<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="codes"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center px-6 py-3">
          <span class="mx-3 text-dark-50 mt-1 font-size-h5 font-weight-bolder"
            >Alternative SKUS</span
          >
          <button
            :disabled="!customer"
            type="button"
            class="btn btn-light-info"
            @click="showAddSkuModal"
          >
            New Item
          </button>
        </div>
      </template>

      <template v-slot:item.systemCode="{ item }">
        {{ item.systemCode }}
      </template>
      <template v-slot:item.status="{ item }">
        <span :class="btnColorSetter(item.status)">
          {{ item.status == 1 ? "Enabled" : "Disabled" }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="row w-100 justify-center">
          <v-icon class="pa-1 mx-1 h2" small @click="() => updateStatus(item)">
            {{ item.status == 1 ? "mdi-archive-cancel" : "mdi-archive-check" }}
          </v-icon>
          <v-icon class="pa-1 mx-1 h2" small @click="() => remove(item)">
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <AddSku
      ref="editSku"
      :actionFunction="addSkus"
      :customer="customer"
      :skuId="actionItem?.id"
    />
  </div>
</template>

<script>
import AddSku from "@/own/components/fulfillment/skus/forms/AddSku";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import SwalService from "@/core/services/swal.service";

export default {
  name: "SkuDatatable",
  props: ["actionItem", "pageLoader", "refresher", "tabName"],
  components: { AddSku },
  data: () => ({
    dialog: false,
    codes: [],
    headers: [
      {
        text: "System Code",
        value: "systemCode",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        class: "text-muted fw-bolder text-uppercase poppins-ls",
        cellClass: "text-dark-75 font-weight-medium poppins-ls",
      },
    ],
  }),
  computed: {
    customer() {
      let customer = this.$store.getters.currentUser.data.id;
      if (!this.$store.getters.isClient) {
        customer = this.$store.getters.getSelectedCustomer;
      }
      return customer;
    },
  },
  methods: {
    loadServerData() {
      // if (!this.serverData) {
      this.pageLoader(true);
      let payload = {
        tab_name: this.tabName,
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post("/fulfillment/skus/edit", payload)
        .then(({ data }) => {
          this.codes = data.sku.codes.map((item) => ({
            ...item,
            is_from_server: true,
          }));
        })
        .finally(() => {
          this.pageLoader(false);
        });
      // }
    },
    btnColorSetter(tp) {
      let res = "text-center badge font-size-sm my-1 py-2 px-4";
      if (tp === 1) {
        return res + " badge-light-primary";
      } else {
        return res + " badge-light-danger";
      }
    },
    showAddSkuModal() {
      this.$refs.editSku.toggleModal();
    },
    findCopy(item) {
      let index = -1;
      this.codes.forEach((sku, ind) => {
        if (sku.systemCode === item.systemCode) {
          index = ind;
        }
      });
      return index;
    },
    addSkus(item) {
      let new_skus = [...this.codes];
      if (this.findCopy(item) === -1) {
        new_skus.push(item);
      } else {
        Swal.fire({
          title: "Warning",
          text: `Item have already existed!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.codes = new_skus;
    },
    updateStatus(item) {
      let new_skus = [...this.codes];
      let index = new_skus.indexOf(item);
      if (new_skus[index].status === 1) {
        new_skus[index].status = 0;
      } else {
        new_skus[index].status = 1;
      }

      this.codes = new_skus;
    },
    remove(item) {
      if (item.is_from_server) {
        SwalService.warningConditionMessage(
          {
            title: SwalService.titles.are_you_sure,
            html: SwalService.messages.you_wont_be_able_to_revert_this,
            confirmButtonText: SwalService.messages.yes_delete_it,
          },
          () => {
            this.$store.commit(SET_PAGE_LOADING, true);
            ApiService.post("/fulfillment/skus/remove_sku_code", {
              sku_code: item.systemCode,
              skuId: this.actionItem.id,
            })
              .then((response) => {
                if (response.data.success) {
                  this.removeSkus(item);
                }
              })
              .finally(() => {
                this.$store.commit(SET_PAGE_LOADING, false);
              });
          }
        );
      } else {
        this.removeSkus(item);
      }
    },
    removeSkus(item) {
      let index = this.codes.indexOf(item);
      if (index === -1) {
        Swal.fire({
          title: "Error",
          text: `Something went wrong!`,
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      } else {
        let new_skus = [...this.codes];
        new_skus.splice(index, 1);
        this.codes = new_skus;
      }
    },
  },
};
</script>
